import { createSlice } from "@reduxjs/toolkit"

export const loader = createSlice({
  name: "loader",
  initialState: {
    value: false,
  },
  reducers: {
    progress: (state) => {
      state.value = true
    },
    stop: (state) => {
      state.value = false
    },
  },
})

// Action creators are generated for each case reducer function
export const { progress, stop } = loader.actions

export default loader.reducer
