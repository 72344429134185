import { createSlice } from "@reduxjs/toolkit"

export const authSlice = createSlice({
  name: "auth",
  initialState: {
    userInfo: localStorage.getItem("user-data") ? JSON.parse(localStorage.getItem("user-data")) : {},
    token: localStorage.getItem("user-token") ? localStorage.getItem("user-token") : null,
    tokenExpiration: localStorage.getItem("user-token-expired-at")
      ? localStorage.getItem("user-token-expired-at")
      : null,
    userType: localStorage.getItem("user-type") ? localStorage.getItem("user-type") : null,
  },
  reducers: {
    loginAction: (state, action) => {
      localStorage.removeItem("user-token")
      localStorage.removeItem("user-token-expired-at")
      localStorage.removeItem("user-type")
      localStorage.removeItem("user-data")

      const { userInfo, token, tokenExpiration, userType } = action.payload
      state.userInfo = userInfo
      state.token = token
      state.tokenExpiration = tokenExpiration
      state.userType = userType
      localStorage.setItem("user-token", token)
      localStorage.setItem("user-token-expired-at", tokenExpiration)
      localStorage.setItem("user-type", userType)
      localStorage.setItem("user-data", JSON.stringify(userInfo))
    },
    updateUserInfoAction: (state, action) => {
      state.userInfo = { ...action.payload }
      localStorage.removeItem("user-data")
      localStorage.setItem("user-data", JSON.stringify(action.payload))
    },
    logoutAction: (state) => {
      state.userInfo = {}
      state.token = null
      state.tokenExpiration = null
      state.userType = null
      localStorage.removeItem("user-token")
      localStorage.removeItem("user-token-expired-at")
      localStorage.removeItem("user-type")
      localStorage.removeItem("user-data")
    },
  },
})

// Action creators are generated for each case reducer function
export const { loginAction, logoutAction, updateUserInfoAction } = authSlice.actions

export default authSlice.reducer
