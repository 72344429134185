export default function Typography(theme) {
  return {
    MuiTypography: {
      palette: {
        root: {
          color: 'red',
        },
      },
      styleOverrides: {
        h4: {
          color: 'red',
        },
        paragraph: {
          marginBottom: theme.spacing(2),
        },
        gutterBottom: {
          marginBottom: theme.spacing(1),
        },
      },
    },
  }
}
