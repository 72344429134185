import "./assets/styles/App.scss"
import { ToastContainer } from "react-toastify"
import { useState } from "react"
import AppRoute from "./AppRoute"
import ThemeProvider from "./Theme"
import { NotificationProvider } from "./Context/NotificationContext"
import ScrollToTop from "./Components/ScrollToTop"
import interceptor from "./Utils/Interceptor"

// replace console.* for disable log on production
if (process.env.NODE_ENV === "production") {
  console.log = () => {}
  console.error = () => {}
  console.debug = () => {}
}

const App = () => {
  interceptor()

  return (
    <ThemeProvider>
      <NotificationProvider>
        <ScrollToTop />
        <ToastContainer />
        <AppRoute />
      </NotificationProvider>
    </ThemeProvider>
  )
}

export default App
