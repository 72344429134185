import { createContext, useContext, useState } from "react"

const NotificationContext = createContext({
  appNotifications: [],
  appNotificationsCount: 0,
  modifyAppNotificationsCount: () => {},
  calendarCount: 0,
  chatCount: 0,
  avatarChange: false,
  modifyCalendarCount: () => {},
  pushCalendarCount: () => {},
  modifyChatCount: () => {},
  pushNotifications: () => {},
  modifyAvatar: () => {},
})

const useNotification = () => {
  return useContext(NotificationContext)
}

const NotificationProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([])
  const [appNotificationsCount, setAppNotificationsCount] = useState(0)
  const [chatCount, setChatCount] = useState(0)
  const [calendarCount, setCalendarCount] = useState(0)
  const [avatarChange, setAvatarChange] = useState(false)

  const pushNotifications = (notifcations) => setNotifications(notifcations)

  const modifyAppNotificationsCount = (count) => setAppNotificationsCount(count)

  const modifyCalendarCount = () => setCalendarCount(calendarCount === 0 ? 0 : calendarCount - 1)

  const pushCalendarCount = (count) => setCalendarCount(count)

  const modifyChatCount = (count) => setChatCount(count)

  const modifyAvatar = () => setAvatarChange(!avatarChange)

  const context = {
    appNotifications: notifications,
    appNotificationsCount: appNotificationsCount,
    calendarCount: calendarCount,
    chatCount: chatCount,
    avatarChange: avatarChange,
    modifyCalendarCount: modifyCalendarCount,
    pushCalendarCount: pushCalendarCount,
    modifyChatCount: modifyChatCount,
    pushNotifications: pushNotifications,
    modifyAvatar: modifyAvatar,
    modifyAppNotificationsCount: modifyAppNotificationsCount,
  }

  return <NotificationContext.Provider value={context}>{children}</NotificationContext.Provider>
}

export { useNotification, NotificationProvider }
