import fetchIntercept from "fetch-intercept"
import store from "../store" //importo toda la store de redux
import { useDispatch } from "react-redux"
import { logoutAction } from "../Store/Auth/index"
import { toast } from "react-toastify"

const Interceptor = () => {
  const dispatch = useDispatch()
  const logOut = () => {
    dispatch(logoutAction())
    window.location = "/"
  }

  return fetchIntercept.register({
    request: function (url, config) {
      //store.dispatch({ type: "loading/progress" })
      const token = localStorage.getItem("user-token")

      const headers = {
        Authorization: `Bearer ${token}`,
        Accept: `application/json`,
      }
      config = { ...config, headers }

      // Modify the url or config here
      return [url, config]
    },

    requestError: function (error) {
      // Called when an error occured during another 'request' interceptor call
      return Promise.reject(error)
    },

    response: function (response) {
      // console.log("response", response)
      //store.dispatch({ type: "loading/stop" })
      if (response.status === 401) {
        logOut()
      }

      if (response.status === 500) {
        toast.error("Error Crítico, intentalo mas tarde.", {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: true,
          progress: undefined,
        })
      }
      // Modify the reponse object
      return response
    },

    responseError: function (error) {
      // console.log("responseError responseError")
      store.dispatch({ type: "loading/stop" })
      // Handle an fetch error
      return Promise.reject(error)
    },
  })
}

export default Interceptor
